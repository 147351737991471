// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import {
  resetFilter,
  setComponentsExhibition,
  setList,
  setKeyword,
  setPage,
} from '../../../stores/Filters/UsedVehicles/FilterV2';
import { clearURLFilters } from '../../../utils/filters/usedVehicles/clearURLSearchParams';
import { mapFiltersListToComponentState } from '../../../utils/filters/usedVehicles/mapFiltersListToComponentState';
import { mobileFieldsPlaceholder } from '../../../utils/filters/usedVehicles/fieldsPlaceholder';

class AdvancedSearchResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total: null,
      filtersList: [],
    };

    this.watchStore();
  }

  handleClearFilters() {
    setComponentsExhibition({
      results: false,
    });
    resetFilter();
    setPage(1);
    setList();
    clearURLFilters();
    setKeyword('');
  }

  getFieldsPlaceHolder() {
    const orderPlaceHolder = {
      sort: {
        '-featured,-ordination': 'Mais relevante',
        '-price': 'Maior preço',
        '+price': 'Menor preço',
        '+brand.keyword': 'Marca A-Z',
        '-brand.keyword': 'Marca Z-A',
        '+name.keyword': 'Modelo A-Z',
        '-name.keyword': 'Modelo Z-A',
      },
    };

    return {
      ...mobileFieldsPlaceholder,
      ...orderPlaceHolder,
    };
  }

  getFormattedKeyword(key, value) {
    return `Palavra chave: ${value}`;
  }

  getFormattedNumericField(key, value) {
    const fieldsPlaceholder = this.getFieldsPlaceHolder();

    const isPriceFilter = key.toLowerCase().includes('price');
    const currencySymbol = isPriceFilter ? 'R$ ' : '';

    return `${fieldsPlaceholder[key]}: ${currencySymbol} ${this.maskedValue(
      value,
    )}`;
  }

  getFormattedSortOption(key, value) {
    const fieldsPlaceholder = this.getFieldsPlaceHolder();

    return `Ordenação: ${fieldsPlaceholder[key][value]}`;
  }

  getFiltersList() {
    const { filtersList: filters, list } = this.state;

    const filtersNormalized = mapFiltersListToComponentState(filters) || {};

    const filterList = Object.entries(filtersNormalized).map(([key, value]) => {
      if (key === "unit") {
        if (!Array.isArray(list) || list.length === 0) return;

        if (list.length === 0) {
          return this.formatFilter(key, "Sem veículos para esta unidade");
        }

        if (list[0].item_unit === undefined) return;
        const unit = list[0].item_unit;

        const unitName = unit.name || "Sem nome da unidade";
        return this.formatFilter(key, unitName);
      }

      return this.formatFilter(key, value);
    });

    return filterList;
  }

  formatFilter(key, value) {
    const fieldsPlaceholder = this.getFieldsPlaceHolder();

    const formatters = {
      keyword: this.getFormattedKeyword.bind(this),
      price: this.getFormattedNumericField.bind(this),
      km: this.getFormattedNumericField.bind(this),
      sort: this.getFormattedSortOption.bind(this),
    };

    const lowerCaseKey = key.toLowerCase();
    const formatterKey = Object.keys(formatters).find(formatter =>
      lowerCaseKey.includes(formatter),
    );

    if (formatterKey) {
      return formatters[formatterKey](key, value);
    }

    return `${fieldsPlaceholder[key]}: ${value}`;
  }

  maskedValue(number) {
    if (!number) return null;

    return Number(number).toLocaleString('pt-br');
  }

  watchStore() {
    window.store.FilterV2.watch(state => {
      this.setState(oldState => ({
        ...oldState,
        total: state.total,
        list: state.list,
        filtersList: state.filtersList,
      }));
    });
  }

  render() {
    const { total } = this.state;

    return (
      <div className="search-result row">
        <button
          className="search-result__clear-button search-result__clear-button--mobile d-md-none"
          type="button"
          onClick={this.handleClearFilters}
        >
          Limpar filtros
        </button>
        <div className="search-result__items-wrapper col-lg-9 col-sm-12">
          Buscando por:
          <div className="search-result__items-wrapper-content">
            {this.getFiltersList().map(filter => (
              <span className="search-result__item">{filter}</span>
            ))}
          </div>
          <button
            className="search-result__clear-button d-none d-md-block"
            type="button"
            onClick={this.handleClearFilters}
          >
            Limpar filtros
          </button>
        </div>
        <div className="search-result__total-wrapper col-lg-3 col-sm-12">
          <strong className="search-result__total">{total}</strong>{' '}
          {total > 1 ? 'resultados encontrados' : 'resultado encontrado'}
        </div>
      </div>
    );
  }
}

export default AdvancedSearchResult;
