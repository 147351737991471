/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

class RotationView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentVersionSlug: this.props.rotationViewData[0].slug,
      activeIndex: 0,
      modalIsVisible: true,
    };

    this.parsedImages = this.parsedImages.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handlePrevVersion = this.handlePrevVersion.bind(this);
    this.handleNextVersion = this.handleNextVersion.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getActiveVersionName = this.getActiveVersionName.bind(this);
    this.handleChangeCurrentVersion =
      this.handleChangeCurrentVersion.bind(this);
  }

  componentDidMount() {
    if (typeof window.CI360 !== 'undefined') {
      window.CI360.init();
    }

    window.helpers.handleOpenRotationViewModal = () => {
      this.setState({
        modalIsVisible: true,
      });
    };

    const { setDropdownInteration } = window.components;
    const versionSelectDropdownConfig = {
      dropdownWrapperSelector: '.rotation-view__version-select',
      toggleButtonSelector: '.version-select__dropdown-active-item',
    };
    setDropdownInteration(versionSelectDropdownConfig);
  }

  handleChangeCurrentVersion(versionSlug) {
    const choicedVersionIndex = this.props.rotationViewData.findIndex(
      versionItem => versionItem.slug === versionSlug,
    );

    this.setState({
      currentVersionSlug: versionSlug,
      activeIndex: choicedVersionIndex,
    });
  }

  handleCloseModal() {
    this.setState({
      modalIsVisible: false,
    });
  }

  handlePrevVersion() {
    const currentActiveIndex = this.state.activeIndex;
    if (currentActiveIndex === 0) return;

    const prevVersion = this.props.rotationViewData[currentActiveIndex - 1];

    this.setState(oldState => ({
      activeIndex: oldState.activeIndex - 1,
      currentVersionSlug: prevVersion.slug,
    }));
  }

  handleNextVersion() {
    const { rotationViewData } = this.props;
    const currentActiveIndex = this.state.activeIndex;

    if (currentActiveIndex === rotationViewData.length - 1) return;

    const nextVersion = this.props.rotationViewData[currentActiveIndex + 1];

    this.setState(oldState => ({
      activeIndex: oldState.activeIndex + 1,
      currentVersionSlug: nextVersion.slug,
    }));
  }

  handleClickOutside(event) {
    if (this.modalContentRef && !this.modalContentRef.contains(event.target)) {
      this.handleCloseModal();
    }
  }

  getActiveVersionName() {
    const { activeIndex } = this.state;

    return this.props.rotationViewData[activeIndex].name;
  }

  parsedImages(versionSlug) {
    const { rotationViewData } = this.props;
    if (!rotationViewData || rotationViewData.length === 0) return null;

    const currentVersionPictures =
      rotationViewData.find(item => item.slug === versionSlug) ||
      rotationViewData[0];

    const pictures = currentVersionPictures.pictures.map(
      picture => picture.main.url,
    );

    return JSON.stringify(pictures);
  }

  render() {
    const {
      rotationViewData,
      stickerDesktopImage,
      stickerMobileImage,
      modelName,
    } = this.props;
    const { currentVersionSlug, modalIsVisible, activeIndex } = this.state;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className="rotation-view-modal"
        data-visible={modalIsVisible}
        onClick={this.handleClickOutside}
      >
        <div
          className="rotation-view-modal__content"
          ref={node => {
            this.modalContentRef = node;
          }}
        >
          <div className="rotation-view-modal__header">
            <h2 className="rotation-view-modal__header-title">
              Confira o modelo em 360°
            </h2>
            <button
              className="rotation-view-modal__header-close"
              title="Fechar"
              type="button"
              onClick={this.handleCloseModal}
            >
              <i className="icon icon-close" />
            </button>
          </div>
          <div className="rotation-view-modal__body">
            <ul className="rotation-view-modal__versions-choices">
              {rotationViewData &&
                rotationViewData.map(item => (
                  <li
                    className="rotation-view-modal__versions-choice"
                    data-slug={item.slug}
                    key={item.slug}
                    data-active={item.slug === currentVersionSlug}
                  >
                    <button
                      className="rotation-view-modal__versions-choice-button"
                      type="button"
                      onClick={() => this.handleChangeCurrentVersion(item.slug)}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
            </ul>
            <div className="rotation-view__version-select version-select">
              <p className="version-select__dropdown-active-item">
                {this.getActiveVersionName()}
                <i className="icon icon-arrow-d" />
              </p>
              <ul className="version-select__options">
                {rotationViewData &&
                  rotationViewData.map(item => (
                    <li
                      data-slug={item.slug}
                      key={item.slug}
                      className="version-select__option"
                      data-active={item.slug === currentVersionSlug}
                    >
                      <button
                        className="rotation-view-modal__versions-choice-button"
                        type="button"
                        onClick={() =>
                          this.handleChangeCurrentVersion(item.slug)
                        }
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="rotation-view-modal__360-feature">
              <div className="rotation-view-modal__sticker">
                <p>
                  Arraste para os lados e visualize o <b>{modelName}</b> em 360°
                </p>
              </div>
              <div className="rotation-view-modal__model">
                {rotationViewData.map(data => (
                  <div
                    className="cloudimage-360"
                    id={`rotation-view-cloudimage${data.slug}`}
                    data-image-list-x={this.parsedImages(data.slug)}
                    data-autoplay
                    data-active={currentVersionSlug === data.slug}
                  />
                ))}
              </div>
              <span className="rotation-view-modal__rotate-indicator">
                <img
                  className="rotation-view-modal__indicator-img indicator-desktop"
                  src={stickerDesktopImage}
                  alt="Detalhe gráfico indicativo de rotação do 360°"
                />
                <img
                  className="rotation-view-modal__indicator-img indicator-mobile"
                  src={stickerMobileImage}
                  alt="Detalhe gráfico indicativo de rotação do 360°"
                />
              </span>
              <div className="rotation-view-modal__arrows">
                <button
                  type="button"
                  className="rotation-view-modal__arrow button-arrow-prev"
                  title="Anterior"
                  data-disabled={activeIndex === 0}
                  onClick={this.handlePrevVersion}
                >
                  <i className="icon icon-arrow-left" />
                </button>
                <button
                  type="button"
                  className="rotation-view-modal__arrow button-arrow-next"
                  title="Próximo"
                  data-disabled={activeIndex === rotationViewData.length - 1}
                  onClick={this.handleNextVersion}
                >
                  <i className="icon icon-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RotationView.defaultProps = {
  rotationViewData: [],
  stickerDesktopImage: '',
  stickerMobileImage: '',
  modelName: '',
};

RotationView.propTypes = {
  rotationViewData: PropTypes.arrayOf(),
  stickerDesktopImage: PropTypes.string,
  stickerMobileImage: PropTypes.string,
  modelName: PropTypes.string,
};

export default RotationView;
