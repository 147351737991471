import '../auto/global';
import './components';
import '../auto/stores';
import './stores';
// Importando o alias para a pasta de componentes.
import '#';
import Carousel from '../auto/carousel';
import Choices from '../auto/choices';
import MicroModal from '../auto/micromodal';
import serviceFactory from '../auto/autoForceServiceFactory';
import serviceConversionFactory from '../auto/autoBoxOfficeServiceFactory';
import railsDateToChronometer from '../auto/utils/railsDateToChronometer';
import fcaServiceApi from '../auto/utils/fcaServiceApi';
import GWMConversionFormApp from '../auto/components/GWM/GWMConversionFormApp';
import { pushDataToDataLayer } from '../auto/utils/pushDataToDataLayer';
import { FilterDataCacheHandler } from '../auto/utils/FilterDataCacheHandler';
import { autoServiceApiV2 } from '../auto/services/autoServiceApiV2';
import { initSectionWhenItIsVisible } from '../auto/utils/initSectionWhenItIsVisible';
import { setDropdownInteration } from '../auto/utils/components';

// Criando um service que será armazenado como Singleton.
window.service = serviceFactory();
window.serviceConversion = serviceConversionFactory();
window.serviceFca = fcaServiceApi;

window.Carousel = Carousel;
window.Choices = Choices;
window.MicroModal = MicroModal;
window.GWMConversionFormApp = GWMConversionFormApp;

window.railsDateToChronometer = railsDateToChronometer;
window.pushDataToDataLayer = pushDataToDataLayer;

window.filterDataCacheHandler = new FilterDataCacheHandler('requestResult');

window.services = {
  autoServiceApiV2,
};

window.helpers = {
  ...window.helpers,
  initSectionWhenItIsVisible,
};

window.components = {
  setDropdownInteration,
};

if (process.env.NODE_ENV === 'development') {
  // Necessário para fazer o Preact ficar disponível no React Dev Tools
  require('preact/debug');

  // Adicionando o app do guide apenas em desenvolvimento
  $(document).ready(() => {
    // require('./guide-app.js');
  });
}

$(document).ready(() => {
  // create Swiper object
  const slide = new Carousel('.slide', {
    autoplay: {
      delay: 5000,
    },
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    on: {
      init: function () {
        window.videoBG.adjustsHeight('.slide');
      },
    },
  });

  const slideThumbs = new Carousel('.swiper-thumbs-container', {
    direction: 'vertical',
    slidesPerView: 5,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });

  const slideImages = new Carousel('.swiper-images-container', {
    direction: 'horizontal',
    navigation: {
      nextEl: '.slide-images__control--next',
      prevEl: '.slide-images__control--prev',
    },
    thumbs: {
      swiper: slideThumbs,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      767: {
        direction: 'vertical',
      },
    },
  });
});
