/* eslint-disable react/no-unused-prop-types */
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import ConversionFormV2 from './ConversionFormV2';
import { getNestedProperty } from '../../../utils/filters/usedVehicles/getNestedProperty';

class ConversionFormAppV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConversionForm: false,
      keyword: null,
    };

    this.watchStore = this.watchStore.bind(this);

    if (this.props.showBasedOnStore) this.watchStore();
  }

  watchStore() {
    const store = window.store[this.props.storeName];
    const showFilterKey = this.props.showFilterKey;

    store.watch(state => {
      this.setState({
        showConversionForm: getNestedProperty(state, showFilterKey),
        keyword: state.keyword,
      });
    });
  }

  render() {
    const { showConversionForm } = this.state;

    if (!showConversionForm) return null;

    const {
      formId,
      showCpf,
      showUnits,
      showDataPermissions,
      dataPermissionsCustomText,
      linkPrivacyPolicy,
      category,
      units,
      product,
      submitButtonLabel,
      showMessageField,
    } = this.props;

    return (
      <div className="conversion-form--no-result-wrapper">
        <ConversionFormV2
          formId={formId}
          showCpf={showCpf}
          showUnits={showUnits}
          showDataPermissions={showDataPermissions}
          dataPermissionsCustomText={dataPermissionsCustomText}
          linkPrivacyPolicy={linkPrivacyPolicy}
          units={units}
          category={category}
          product={product}
          keyword={this.state.keyword}
          submitButtonLabel={submitButtonLabel}
          showMessageField={showMessageField}
        />
      </div>
    );
  }
}

ConversionFormAppV2.defaultProps = {
  storeName: 'SearchFilter',
  showFilterKey: 'showConversionForm',
  showBasedOnStore: false,
  submitButtonLabel: 'Estou interessado',
  showMessageField: false,
};

ConversionFormAppV2.propTypes = {
  formId: PropTypes.string.isRequired,
  showCpf: PropTypes.bool,
  showUnits: PropTypes.bool,
  showDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  product: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  storeName: PropTypes.string,
  showFilterKey: PropTypes.string,
  showBasedOnStore: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  showMessageField: PropTypes.bool,
};

export default ConversionFormAppV2;
