import { Component } from 'preact';
import PropTypes from 'prop-types';
import ClassicUsedVehiclesCard from '../../../Cards/UsedVehicles/ClassicUsedVehiclesCard';
import CardPlaceholder from '../../../Cards/Placeholder';

class ListUsedVehiclesClassic extends Component {
  constructor(props) {
    super(props);

    const isMobile = window.matchMedia('(max-width: 576px)').matches;
    const placeholderAmount = isMobile ? 2 : 8;

    this.placeholderCards = Array.from({ length: placeholderAmount }).map(
      (_, index) => <CardPlaceholder key={`placeholder-${index}`} long />,
    );
  }

  componentDidUpdate() {
    window.matchHeight.match('.used-vehicles-listing__content');
  }

  render() {
    const { moduleLink, vehiclesList, loading, loadingMore, CustomCard } =
      this.props;

    return (
      <div
        className="used-vehicles-listing__content"
        data-vehicles-list-empty={!loading && vehiclesList.length === 0}
      >
        {loading && !loadingMore && this.placeholderCards}

        {vehiclesList.length > 0 &&
          vehiclesList.map((vehicle, index) => (
            <CustomCard
              key={vehicle.slug}
              slug={vehicle.slug}
              moduleLink={moduleLink}
              badge={vehicle.badge}
              brand={vehicle.brand}
              id={vehicle.id}
              itemImage={vehicle.item_image.middle_image}
              itemUnit={vehicle.item_unit}
              kind={vehicle.kind}
              name={vehicle.name}
              model={vehicle.model}
              version={vehicle.subtitle}
              modelYear={vehicle.model_year}
              fabricationYear={vehicle.fabrication_year}
              seals={vehicle.seals}
              subtitle={vehicle.subtitle}
              km={vehicle.km}
              exchange={vehicle.exchange}
              fuelText={vehicle.fuel_text}
              price={vehicle.price}
              oldPrice={vehicle.old_price}
              isOnFirstPage={index < 4}
            />
          ))}
        {!loading && vehiclesList.length === 0 && (
          <p className="used-vehicles-listing__no-vehicles-message">
            Nenhum veículo seminovo encontrado.
          </p>
        )}
      </div>
    );
  }
}

ListUsedVehiclesClassic.defaultProps = {
  vehiclesList: [],
  loading: true,
  loadingMore: false,
  moduleLink: null,
  CustomCard: ClassicUsedVehiclesCard,
};

ListUsedVehiclesClassic.propTypes = {
  vehiclesList: PropTypes.arrayOf(),
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  moduleLink: PropTypes.string,
  CustomCard: PropTypes.element,
};

export default ListUsedVehiclesClassic;
