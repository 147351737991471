import MainUsedVehiclesCarousel from '../auto/components/Carousels/UsedVehicles/MainUsedVehiclesCarousel';
import NewVehiclesLazyV2Carousel from '../auto/components/Carousels/NewVehicles/NewVehiclesLazyV2';
import SimpleFilterNewVehicleV2 from '../auto/components/NewVehicleV2/SimpleFilterV2';
import UsedVehiclesFilter from '../auto/components/Filters/UsedVehiclesFilter/UsedVehiclesFilterApp';
import ListUsedVehiclesApp from '../auto/components/Listings/UsedVehicles/Classic';
import UsedVehiclesConversionFormNoResult from '../auto/components/Forms/ConversionFormAppV2';
import RotationView from '../auto/components/RotationView';

(() => {
  const components = {
    MainUsedVehiclesCarousel,
    NewVehiclesLazyV2Carousel,
    SimpleFilterNewVehicleV2,
    UsedVehiclesFilter,
    ListUsedVehiclesApp,
    UsedVehiclesConversionFormNoResult,
    RotationView,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();
