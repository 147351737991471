import autoServiceApi from '../../autoServiceApi';
import { uniqByKeepFirst } from '../../uniqByKeepFirst';
import { usedModelsKmOptions } from './usedModelsKmOptions';

const extractFilterBrands = filterOptionsResponse => {
  const brands = filterOptionsResponse.brands.map(({ title }) => ({
    label: title,
    value: title,
  }));
  return uniqByKeepFirst(brands, brandOption => brandOption.value);
};

const extractFilterModels = (filterOptionsResponse, filterBrands) => {
  const filterModels = {};

  filterBrands.forEach(({ value: brand }) => {
    const models = filterOptionsResponse.models[brand] || [];
    filterModels[brand] = uniqByKeepFirst(
      models.map(model => ({ label: model.title, value: model.title })),
      modelOption => modelOption.value,
    );
  });

  return filterModels;
};

const createFilterYears = yearsResponse => {
  const years = Object.keys(yearsResponse).map(year => parseInt(year, 10));
  const yearBegin = Math.min(...years);
  const yearEnd = Math.max(...years);

  const filterYears = [];
  // eslint-disable-next-line no-plusplus
  for (let i = yearEnd; i >= yearBegin; i--) {
    filterYears.push({ label: i, value: i });
  }

  return filterYears;
};

export const getUsedModelsFilterOptions = async withNewVehicle => {
  const filterOptionsResponse = await autoServiceApi.getSelectFilterOptionsV2([
    { new_vehicle: withNewVehicle },
  ]);

  const filterBrands = extractFilterBrands(filterOptionsResponse);
  const filterModels = extractFilterModels(filterOptionsResponse, filterBrands);
  const filterYears = createFilterYears(filterOptionsResponse.years);

  return {
    options: {
      brands: filterBrands,
      models: filterModels,
      min_model_year: filterYears,
      max_model_year: filterYears,
      min_km: usedModelsKmOptions,
      max_km: usedModelsKmOptions,
    },
  };
};
