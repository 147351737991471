// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import SimpleFilter from './SimpleFilter';
import AdvancedFilter from './AdvancedFilter';
import { restoreFilterFromURLParams } from '../../../utils/filters/usedVehicles/restoreFilterFromURLParams';
import AdvancedFilterModal from './AdvancedFilter/AdvancedFilterModal';

class UsedVehiclesFilterApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdvancedFilter: false,
    };

    this.watchStore();

    this.renderAdvancedFilter = this.renderAdvancedFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.shouldSetURLSearchParams) restoreFilterFromURLParams();
  }

  watchStore() {
    window.store.FilterV2.watch(state => {
      this.setState(oldState => ({
        ...oldState,
        showAdvancedFilter: state.componentsExhibition.advancedFilter,
      }));
    });
  }

  isMobile() {
    return window.innerWidth <= 768;
  }

  renderAdvancedFilter() {
    const {
      units,
      customFieldsPlaceholder,
      showAdvancedFilterFooter,
      advancedFilterModalTitle,
      withNewVehicle,
    } = this.props;

    if (this.isMobile())
      return (
        <AdvancedFilterModal title={advancedFilterModalTitle}>
          <AdvancedFilter
            shouldSetURLSearchParams
            isMobile
            units={units}
            customFieldsPlaceholder={customFieldsPlaceholder}
            showFooter={showAdvancedFilterFooter}
            withNewVehicle={withNewVehicle}
          />
        </AdvancedFilterModal>
      );

    return (
      <AdvancedFilter
        shouldSetURLSearchParams
        units={units}
        customFieldsPlaceholder={customFieldsPlaceholder}
        showFooter={showAdvancedFilterFooter}
        withNewVehicle={withNewVehicle}
      />
    );
  }

  render() {
    const { showAdvancedFilter } = this.state;
    const {
      buttonFilterLabel,
      buttonOrderLabel,
      dropdownOrderLabel,
      showDropdownCloseButton,
    } = this.props;

    return (
      <div className="used-vehicles-filter">
        {showAdvancedFilter && this.renderAdvancedFilter()}
        {!showAdvancedFilter && (
          <SimpleFilter
            shouldSetURLSearchParams
            buttonFilterLabel={buttonFilterLabel}
            buttonOrderLabel={buttonOrderLabel}
            dropdownOrderLabel={dropdownOrderLabel}
            showDropdownCloseButton={showDropdownCloseButton}
          />
        )}
      </div>
    );
  }
}

UsedVehiclesFilterApp.defaultProps = {
  shouldSetURLSearchParams: false,
  units: [],
  customFieldsPlaceholder: null,
  buttonFilterLabel: 'Filtrar',
  buttonOrderLabel: 'Ordenar',
  dropdownOrderLabel: 'Ordenar por:',
  showDropdownCloseButton: false,
  showAdvancedFilterFooter: false,
  advancedFilterModalTitle: 'Filtrar pesquisa',
  withNewVehicle: false,
};

UsedVehiclesFilterApp.propTypes = {
  shouldSetURLSearchParams: PropTypes.bool,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  customFieldsPlaceholder: PropTypes.objectOf(PropTypes.string),
  buttonFilterLabel: PropTypes.string,
  buttonOrderLabel: PropTypes.string,
  dropdownOrderLabel: PropTypes.string,
  showDropdownCloseButton: PropTypes.bool,
  showAdvancedFilterFooter: PropTypes.bool,
  advancedFilterModalTitle: PropTypes.string,
  withNewVehicle: PropTypes.bool,
};

export default UsedVehiclesFilterApp;
