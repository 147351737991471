import { Component } from 'preact';
import PropTypes from 'prop-types';
import List from './List';
import {
  nextPage,
  resetList,
  setList,
  setComponentsExhibition,
  setSort,
  incrementList,
  setMethodApi,
} from '../../../../stores/Filters/UsedVehicles/FilterV2';
import ButtonLoadMore from './ButtonLoadMore';
import PaginationCount from './PaginationCount';

class ListUsedVehiclesClassicApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehiclesList: [],
      hasMorePages: false,
      loading: true,
      loadingMore: false,
      showList: true,
    };

    resetList([]);
    if (this.props.channelUsedVehiclesSort)
      setSort(this.props.channelUsedVehiclesSort);

    if (!this.state.loading && this.state.vehiclesList.length === 0) {
      setComponentsExhibition({
        formNoResults: false,
      });
    }

    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount() {
    const { searchMethod } = this.props;

    setMethodApi(searchMethod);
    this.watchStore();
    setList();
  }

  handleLoadMore() {
    this.setState(oldState => ({
      ...oldState,
      loadingMore: true,
    }));

    nextPage();
    incrementList();
  }

  watchStore() {
    window.store.FilterV2.watch(state => {
      this.setState({
        vehiclesList: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        hasMorePages: state.total / state.per_page > state.page,
        showList: !state.componentsExhibition.formNoResults,
      });
    });
  }

  render() {
    const { vehiclesList, loading, loadingMore, hasMorePages, showList } =
      this.state;
    const { moduleLink, buttonLoadingMoreText, showPaginationCount } =
      this.props;

    if (!showList) return null;

    return (
      <div className="used-vehicles-listing__wrapper">
        <List
          vehiclesList={vehiclesList}
          loading={loading}
          loadingMore={loadingMore}
          showLoadMore={hasMorePages}
          moduleLink={moduleLink}
        />
        {showPaginationCount && <PaginationCount />}
        {hasMorePages && (
          <ButtonLoadMore
            loadingMore={loadingMore}
            handleLoadMore={this.handleLoadMore}
            text={buttonLoadingMoreText}
          />
        )}
      </div>
    );
  }
}

ListUsedVehiclesClassicApp.defaultProps = {
  moduleLink: '/seminovos',
  channelUsedVehiclesSort: null,
  buttonLoadingMoreText: 'Quero ver mais modelos',
  showPaginationCount: false,
  searchMethod: 'getUsedModels',
};

ListUsedVehiclesClassicApp.propTypes = {
  moduleLink: PropTypes.string,
  channelUsedVehiclesSort: PropTypes.string,
  buttonLoadingMoreText: PropTypes.string,
  showPaginationCount: PropTypes.bool,
  searchMethod: PropTypes.string,
};

export default ListUsedVehiclesClassicApp;
