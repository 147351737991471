// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Textarea extends Component {
  render() {
    const {
      customClasses,
      showLabels,
      title,
      name,
      value,
      required,
      placeholder,
      handleChange,
      setRef,
      ...otherProps
    } = this.props;

    return (
      <div className={classNames('form__text-field', customClasses)}>
        {showLabels && (
          <label className="form__text-field-label" htmlFor={name}>
            {title}
          </label>
        )}
        <textarea
          className="form__text-field-common"
          name={name}
          value={value}
          required={required}
          placeholder={placeholder}
          onChange={handleChange}
          ref={setRef}
          data-bouncer-target={`#invalid-${name}`}
          {...otherProps}
        />
        <div id={`invalid-${name}`} className="invalid-feedback" />
      </div>
    );
  }
}

Textarea.propTypes = {
  customClasses: PropTypes.string,
  showLabels: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  setRef: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
};
